.home-landing-sections
    background-image: url("../../../assets/img/landing-bg.jpg")
    background-size: cover
    background-repeat: no-repeat
    background-position-y: 60%

.home-landing-sections-1
    background-image: url("../../../assets/img/landing-bg-1.jpg")
    background-size: cover
    background-repeat: no-repeat
    background-position-y: 60%

.home-landing-sections-2
    background-image: url("../../../assets/img/landing-bg-2.jpg")
    background-size: cover
    background-repeat: no-repeat
    background-position-y: 60%

.home-landing-sections-3
    background-image: url("../../../assets/img/landing-bg-3.jpg")
    background-size: cover
    background-repeat: no-repeat
    background-position-y: 60%


.home-landing-sections-content 
    z-index: 10
    // backdrop-filter: blur(20px)

.home-landing-sections-start-button
    background: linear-gradient(180deg, #008ED4 0%, #1E3365 100%)
